.main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  min-height: 100vh;
}

.description {
  display: inherit;
  justify-content: inherit;
  align-items: inherit;
  font-size: 0.85rem;
  max-width: var(--max-width);
  width: 100%;
  z-index: 2;
  font-family: var(--font-mono);
}

.description a {
  display: flex;
  justify-content: center;
  text-decoration: underline;
  align-items: center;
  gap: 0.5rem;
}

.description p {
  position: relative;
  cursor: pointer;
  margin: 0;
  padding: 1rem;
  background-color: rgba(var(--callout-rgb), 0.5);
  border: 1px solid rgba(var(--callout-border-rgb), 0.3);
  border-radius: var(--border-radius);
}

.code {
  font-weight: 700;
  font-family: var(--font-mono);
}

.flexx {
  display: flex;
  justify-content: space-between;
  width: 12rem;
}

.grid {
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(3, minmax(20%, 25%));
  width: var(--max-width);
  max-width: 100%;
}

.card {
  padding: 1rem 1.2rem;
  border-radius: var(--border-radius);
  background: rgba(var(--card-rgb), 0);
  border: 1px solid rgba(var(--card-border-rgb), 0);
  transition: background 200ms, border 200ms;
}

.card p {
  font-weight: 600;
  font-family: var(--font-mono);
  display: flex;
  justify-content: space-between;
}

.card p span {
  color: rgb(0, 102, 255);
}

.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 3rem;
  align-items: center;
  position: relative;
  padding: 3rem 0;
}

input {
  outline: none;
  position: relative;
  display: block;
  width: 500px;
  position: relative;
  padding: 1rem;
  background-color: rgba(var(--callout-rgb), 0.5);
  border: 1px solid rgba(var(--callout-border-rgb), 0.3);
  border-radius: var(--border-radius);
  margin: 2rem auto;
  color: rgb(255, 255, 255);
  font-weight: 700;
  font-family: var(--font-mono);
}
::-webkit-input-placeholder { color: rgb(214, 214, 214);} 
:-moz-placeholder { color: rgb(214, 214, 214); }
::-moz-placeholder { color: rgb(214, 214, 214); }
:-ms-input-placeholder { color: rgb(214, 214, 214); }

button {
  outline: none;
  position: relative;
  cursor: pointer;
  display: block;
  position: relative;
  margin: 0 auto;
  padding: 1rem;
  background: rgba(19, 16, 179, 0.397);
  border: 1px solid rgba(var(--callout-border-rgb), 0.3);
  border-radius: var(--border-radius);
  margin: 0 auto;
  color: rgb(255, 255, 255);
  transition: 0.3s;
}

button:hover {
  background: var(--secondary-glow2);
}

.center::before {
  background: var(--secondary-glow);
  border-radius: 50%;
  width: 480px;
  height: 360px;
  margin-left: -400px;
}

.center::after {
  background: var(--primary-glow);
  width: 240px;
  height: 180px;
  z-index: -1;
}

.center::before,
.center::after {
  content: '';
  left: 50%;
  position: absolute;
  filter: blur(45px);
  transform: translateZ(0);
}


/* Mobile */
@media (max-width: 700px) {
  .content {
    padding: 4rem;
  }

  h1{
    font-size: 1.5rem;
  }

  .flexx {
    width: 100vw;
  }

  .grid {
    grid-template-columns: 1fr;
    margin-bottom: 120px;
    max-width: 320px;
    text-align: center;
  }

  .card {
    padding: 1rem 2.5rem;
  }

  input {
    width: 80vw;
  }

  .center {
    padding: 8rem 0 6rem;
  }

  .center::before {
    transform: none;
    height: 300px;
  }

  .description {
    font-size: 0.8rem;
  }

  .description a {
    padding: 1rem;
  }

  .description p,
  .description div {
    display: flex;
    justify-content: center;
    position: fixed;
    width: 100%;
  }

  .description p {
    align-items: center;
    inset: 0 0 auto;
    padding: 2rem 1rem 1.4rem;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid rgba(var(--callout-border-rgb), 0.25);
    background: linear-gradient(
      to bottom,
      rgba(var(--background-start-rgb), 1),
      rgba(var(--callout-rgb), 0.5)
    );
    background-clip: padding-box;
    backdrop-filter: blur(24px);
  }

  .description div {
    align-items: flex-end;
    pointer-events: none;
    inset: auto 0 0;
    padding: 2rem;
    height: 200px;
    background: linear-gradient(
      to bottom,
      transparent 0%,
      rgb(var(--background-end-rgb)) 40%
    );
    z-index: 1;
  }
}

